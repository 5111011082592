import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Fade } from '@material-ui/core'
import { H1, H3, Text } from '@system'
import classNames from 'classnames'
import font from '@helpers/font'
import ContentContainer from '@system/content-container'
import Icon from '@system/icon'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary,
    height: '100%',
  },
  title: {
    display: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
  showTitle: {
    display: 'initial',
  },
  greyText: {
    marginTop: '6rem',
    color: theme.palette.text.tertiary,
    textAlign: 'center',
  },
  questionText: ({ questionText }) => ({
    textAlign: 'center',
    ...makeResponsiveFontSizes(
      // proportional font size reduction for questions longer than 170 characters
      (questionText.length <= 170 ? 1 : 170 / questionText.length) * 64
    ),
  }),
  bottomContainer: {
    width: '100%',
    marginBottom: '3.5rem',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '3rem 0',
    width: '100%',
  },
  selectButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: 'max-content',
    flexGrow: 1,
    flexBasis: 0,
  },
  selectButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    height: '4rem',
    borderRadius: '50%',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
      height: '3rem',
      width: '3rem',
      minWidth: 'unset',
    },
  },
  mainLegend: {
    marginTop: '0',
  },
  legendText: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  selectButtonText: {
    margin: 'auto',
    fontWeight: theme.typography.fontWeightMedium,
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  navButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  navButton: {
    margin: '0 1vw',
  },
  navButtonText: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  backButton: {
    color: theme.palette.primary.main,
  },
  chevronLeft: {
    marginRight: '0.3rem',
  },
  chevronRight: {
    marginLeft: '0.3rem',
  },
}))

const InteractiveQuizQuestion = (props) => {
  const {
    handleBack,
    handleNext,
    updateScores,
    title,
    subtitle,
    position,
    activeStep,
    prevSelected,
    blok: { questionText, scaleLegendOne, scaleLegendThree, scaleLegendFive },
  } = props

  const classes = useStyles(props.blok)
  const [selected, setSelected] = useState(prevSelected || null)

  const updateSelected = (num) => {
    setSelected(num)
    updateScores(position, num)
  }

  return (
    <SbEditable content={props.blok}>
      <Fade timeout={500} in={activeStep === position}>
        <ContentContainer>
          <Box className={classes.questionContainer}>
            <Text className={classes.greyText}>
              <span
                className={classNames(classes.title, {
                  [classes.showTitle]: !!title,
                })}
              >
                {title + ' '}
              </span>
              {subtitle}
            </Text>
            <H1 component="h2" color="primary" className={classes.questionText}>
              {questionText}
            </H1>
            <Box className={classes.bottomContainer}>
              <Text
                className={classNames(classes.greyText, classes.mainLegend)}
              >
                {scaleLegendOne || scaleLegendThree || scaleLegendFive
                  ? `(${scaleLegendOne ? '1=' + scaleLegendOne + ',' : ''} ${
                      scaleLegendThree ? '3=' + scaleLegendThree + ',' : ''
                    }
                ${scaleLegendFive ? '5=' + scaleLegendFive : ''})`
                  : ''}
              </Text>
              <Box className={classes.selectContainer}>
                {[1, 2, 3, 4, 5].map((number) => (
                  <Box
                    key={`select${number}`}
                    className={classes.selectButtonContainer}
                  >
                    <Button
                      className={classNames(classes.selectButton, {
                        [classes.selectedButton]: selected === number,
                      })}
                      onClick={() => updateSelected(number)}
                    >
                      <H3 className={classes.selectButtonText}>{number}</H3>
                    </Button>
                    {number === 1 && (
                      <Text className={classes.legendText}>
                        {scaleLegendOne}
                      </Text>
                    )}
                    {number === 3 && (
                      <Text className={classes.legendText}>
                        {scaleLegendThree}
                      </Text>
                    )}
                    {number === 5 && (
                      <Text className={classes.legendText}>
                        {scaleLegendFive}
                      </Text>
                    )}
                  </Box>
                ))}
              </Box>
              <Box className={classes.navButtonContainer}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleBack(position)}
                  className={classNames(classes.navButton, classes.backButton)}
                >
                  <Icon styles={classes.chevronLeft}>ChevronLeft</Icon>
                  <span className={classes.navButtonText}>Back</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext(position)}
                  className={classes.navButton}
                  disabled={!selected}
                >
                  <span className={classes.navButtonText}>Next</span>
                  <Icon styles={classes.chevronRight}>ChevronRight</Icon>
                </Button>
              </Box>
            </Box>
          </Box>
        </ContentContainer>
      </Fade>
    </SbEditable>
  )
}

export default InteractiveQuizQuestion
